// import React from 'react';
import React, { useState, useEffect } from "react";
import {graphql } from 'gatsby'
import HomeModules from './home-modules'
import FormTemplate from './form-template';
import SEO from "../Components/Seo/seo"
const HomeTemplate = (props) => {
  const GQLPage = props.data.glstrapi?.article
  const GQLAreaGuides = props.data.glstrapi?.areaguides;
  const GQLOffices = props.data.glstrapi?.ourOffices;
  const GQLTeams = props.data.glstrapi?.teams;
  const GQLModules = props.data.glstrapi?.article?.Modules;
  const GQLArticlesTeams = props.data.glstrapi?.teams;
  const GQLConfig = props.data.glstrapi?.globalConfig;
  console.log("home template")
    return  (
      <>
      <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Title} description={GQLPage.Meta_Description} />
      <HomeModules GQLTeams={GQLTeams} GQLPage={GQLPage} GQLModules={GQLModules} GQLAreaGuides={GQLAreaGuides} GQLOffices={GQLOffices} GQLArticlesTeams={GQLArticlesTeams} GQLConfig={GQLConfig} />
      </>
    )
  }

export default HomeTemplate;

export const pageQuery = graphql`
query HomeQuery ($articleId: ID!) {
  glstrapi {
    globalConfig {
      Sidebar_Title
      Sidebar_Title_Description
      Toogle_Description
      Info_Toogle
      Email_Postcode_Mapping
    }
   
    article(id: $articleId, publicationState: LIVE) {
      Title
      Select_Template
      Meta_Title
      Meta_Description
      Page_Class
      Body_Background_Color
      Article_With_Sidebar
      BrokerCode_Data {
        id
        BrokerCode
        showBrokerCode
        BrokerCodeImg {
          alternativeText
          url
        }
      }
      Select_Popular_Search
      Show_Holidays_Banner
      Select_Staff {
        id
        URL
        Staff_Image {
          url
          alternativeText
        }
        imagetransforms
        Name
        Designation
        Phone_Number
      }
    
      Modules {
        ... on GLSTRAPI_ComponentComponentsBannerSlider {
          id
          __typename
          Banner_Slides {
            Slider_Content
            Slider_Title
            Slider_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 585
                    quality: 100
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
            banner_video_url,
            banner_link
          }
        }
        ... on GLSTRAPI_ComponentComponentsShortIntro {
          id
          __typename
          Short_Intro_Title
          Short_Intro_Content
          Select_Member {
            id
            URL
            Staff_Image {
              url
              alternativeText
            }
            imagetransforms
            Name
            Designation
            Phone_Number
          }
          CTA {
            CTA_Custom_Link
            CTA_Label
            CTA_Link {
              Primary_URL
            }
          }
          Section_Id
          App_Links {
            App_Store_Link
            Playstore_Link
          }
        }
      
        ... on GLSTRAPI_ComponentComponentsImageGrids {
          id
          __typename
          Section_Title
          Section_Introtext
          Grid_List {
            Grid_Title
            Grid_Content
            Grid_Custom_Link
            Link_New_Tab
            Grid_Link_Label
            Grid_Link {
              Primary_URL
            }
            Grid_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: AUTO
                    width: 654
                    quality: 100
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )    
                }
              }
            }
          }
        }
        ... on GLSTRAPI_ComponentComponentsModules {
          id
          __typename
          Select_Module
        }
        ... on GLSTRAPI_ComponentComponentsBalgoresBoostTop {
          id
          __typename
          Balogres_Boost_Top_Left_Content
          Balogres_Boost_Top_Right_Video_URL
          Balogres_Boost_Top_Right_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 540
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
          Balgores_Boost_Counter {
            Value
            Value_Content
            Value_Type
          }
        }
    
        ... on GLSTRAPI_ComponentComponentsTileBlocks {
          id
          __typename
          Tile_Content
          Image_Position
          Tile_Youtube_Video_URL
          Tile_Custom_Link
          Tile_Link_Label
          Tile_Title
          Tile_Style
          Tile_Background
          Tile_Counter {
            Value
            Value_Content
            Value_Type
          }
          Tile_Accordion {
            id
            Accordion_Title
            Accordion_Content
          }
          Tile_Link {
            Primary_URL
          }
          Tile_Image {
            url
            alternativeText
            url_sharp {
              childImageSharp {
                gatsbyImageData(formats: AUTO
                  width: 540
                  quality: 100
                  layout: FIXED
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )    
              }
            }
          }
        }       
      }      
    }
  }
}`
